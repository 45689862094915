.react-bootstrap-table-page-btns-ul{float:right;margin-top:0!important}.react-bootstrap-table-pagination-list-hidden{display:none}
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-left: 0;
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

.react-spinner-loader-swing div {
  border-radius: 50%;
  float: left;
  height: 1em;
  width: 1em;
}
.react-spinner-loader-swing div:nth-of-type(1) {
  background: linear-gradient(to right, #385c78 0%, #325774 100%);
}
.react-spinner-loader-swing div:nth-of-type(2) {
  background: linear-gradient(to right, #325774 0%, #47536a 100%);
}
.react-spinner-loader-swing div:nth-of-type(3) {
  background: linear-gradient(to right, #4a5369 0%, #6b4d59 100%);
}
.react-spinner-loader-swing div:nth-of-type(4) {
  background: linear-gradient(to right, #744c55 0%, #954646 100%);
}
.react-spinner-loader-swing div:nth-of-type(5) {
  background: linear-gradient(to right, #9c4543 0%, #bb4034 100%);
}
.react-spinner-loader-swing div:nth-of-type(6) {
  background: linear-gradient(to right, #c33f31 0%, #d83b27 100%);
}
.react-spinner-loader-swing div:nth-of-type(7) {
  background: linear-gradient(to right, #da3b26 0%, #db412c 100%);
}
.react-spinner-loader-shadow {
  clear: left;
  padding-top: 1.5em;
}
.react-spinner-loader-shadow div {
  filter: blur(1px);
  float: left;
  width: 1em;
  height: .25em;
  border-radius: 50%;
  background: #e3dbd2;
}
.react-spinner-loader-shadow .react-spinner-loader-shadow-l {
  background: #d5d8d6;
}
.react-spinner-loader-shadow .react-spinner-loader-shadow-r {
  background: #eed3ca;
}
@keyframes ball-l {
  0%, 50% {
    transform: rotate(0) translateX(0);
  }
  100% {
    transform: rotate(50deg) translateX(-2.5em);
  }
}
@keyframes ball-r {
  0% {
    transform: rotate(-50deg) translateX(2.5em);
  }
  50%,
  100% {
    transform: rotate(0) translateX(0)
  }
}
@keyframes shadow-l-n {
  0%, 50% {
    opacity: .5;
    transform: translateX(0);
  }
  100% {
    opacity: .125;
    transform: translateX(-1.75em);
  }
}
@keyframes shadow-r-n {
  0% {
    opacity: .125;
    transform: translateX(1.75em);
  }
  50%,
  100% {
    opacity: .5;
    transform: translateX(0);
  }
}
.react-spinner-loader-swing-l {
  animation: ball-l .425s ease-in-out infinite alternate;
}
.react-spinner-loader-swing-r {
  animation: ball-r .425s ease-in-out infinite alternate;
}
.react-spinner-loader-shadow-l {
  animation: shadow-l-n .425s ease-in-out infinite alternate;
}
.react-spinner-loader-shadow-r {
  animation: shadow-r-n .425s ease-in-out infinite alternate;
}


.react-spinner-loader-svg-calLoader {
  width: 230px;
  height: 230px;
  transform-origin: 115px 115px;
  animation: 1.4s linear infinite loader-spin;
}

.react-spinner-loader-svg-cal-loader__path {

  animation: 1.4s ease-in-out infinite loader-path;
}

@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader-path {
  0% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
  50% {
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }
  100% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}


.react-spinner-loader-svg svg {
  transform-origin: 50% 65%;
}

.react-spinner-loader-svg svg polygon {
  stroke-dasharray: 17;
  animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 136;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}



.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}
@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

/*# sourceMappingURL=ReactToastify.css.map */
.fileUploader {
	width: 100%;
}

.fileContainer {
	background: #fff;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
	position: relative;
	border-radius: 10px;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 10px auto;
	transition: all 0.3s ease-in;
}

.fileContainer input {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.fileContainer p {
	font-size: 12px;
	margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
	max-width: 300px;
	font-size: 12px;
	color: red;
	text-align: left;
}

.fileContainer .chooseFileButton {
	padding: 6px 23px;
	background: #3f4257;
	border-radius: 30px;
	color: white;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: none;
}

.fileContainer .chooseFileButton:hover {
	background: #545972;
}

.fileContainer .uploadFilesButton {
	padding: 5px 43px;
	background: transparent;
	border-radius: 30px;
	color: #3f4257;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: 1px solid #3f4257;
}

.fileContainer .uploadFilesButton:hover {
	background: #3f4257;
	color: #fff;
}

.fileContainer .uploadIcon {
	width: 50px;
	height: 50px;
}

.fileContainer .uploadPicturesWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.fileContainer .uploadPictureContainer {
	width: 25%;
	margin: 5%;
	padding: 10px;
	background: #edf2f6;
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #d0dbe4;
	position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
	width: 100%;
}

.fileContainer .deleteImage {
	position: absolute;
	top: -9px;
	right: -9px;
	color: #fff;
	background: #ff4081;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	font-size: 26px;
	font-weight: bold;
	line-height: 30px;
	width: 30px;
	height: 30px;
}

.flipMove {
	display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

